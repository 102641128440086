
<template>
  <button @click="remove" class="btn pl-4 pr-4 btn-sm btn-danger" ><i class="fa fa-trash"></i> Excluir </button>
</template>
<script>
import Swal from "sweetalert2";
import BooksPagesStore from "@/modules/books-pages/books-pages-store";

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    remove() {

      Swal.fire({
        title: "Atenção!",
        text: "Deseja mesmo excluir esta página de livro? isso não poderar ser desfeito!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, excluir!",
      }).then((result) => {
        if (result.value) {
          BooksPagesStore.delete(this.value.id).then(() => {
            this.$emit("change", this.value)
            Swal.fire(
                "Removido!",
                "Página de livro excluida com sucesso.",
                "success"
            );
          }).catch(() => {
            Swal.fire(
                "Erro!",
                "Erro ao remover a página de livro.",
                "error"
            );
          });
        }
      });


    }
  }
}
</script>
