
<template>
  <div class="d-inline">
    <b-button v-b-modal="'modal-'+value.id" class="btn pl-4 pr-4 btn-sm btn-primary"><i class="fa fa-eye"></i> Ver</b-button>
    <b-modal :id="'modal-'+value.id" :title="value.name">
      <p>
        <strong>ID: </strong> {{ value.id }}<br>
        <strong>Página: </strong> {{ value.page }}<br>
        <strong>Livro: </strong> {{ value.books.label }}
        <img :src="value.image" alt="">
      </p>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>
