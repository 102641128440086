
import BooksPagesService from "@/modules/books-pages/books-pages-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BooksPagesService.create(payload).then(response => {
                resolve({
                    message: "BooksPages criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BooksPages",
                    success: false,
                    error
                });
            })
        })
    },
    update(id,payload) {
        return new Promise((resolve, reject) => {
            BooksPagesService.update(id,payload).then(response => {
                resolve({
                    message: "BooksPages atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BooksPages",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BooksPagesService.remove(id).then(response => {
                resolve({
                    message: "BooksPages removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BooksPages",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BooksPagesService.delete(id).then(response => {
                resolve({
                    message: "BooksPages deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BooksPages",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BooksPagesService.get(id).then(response => {
                resolve({
                    message: "BooksPages encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksPages",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BooksPagesService.paginate(query).then(response => {
                resolve({
                    message: "BooksPages encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksPages",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BooksPagesService.all().then(response => {
                resolve({
                    message: "BooksPages encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BooksPages",
                    success: false,
                    error
                });
            })
        })
    }
}
